import {AccountActionTypes} from "./constants";

const INIT_STATE = {
    loading: false,
};

export type AccountActionType = {
    type:
        | AccountActionTypes.API_RESPONSE_SUCCESS
        | AccountActionTypes.API_RESPONSE_ERROR
        | AccountActionTypes.GET_CUSTOMERS
        | AccountActionTypes.GET_CUSTOMER
        | AccountActionTypes.UPDATE_CUSTOMER
        | AccountActionTypes.LOGOUT
    ;
    payload: {} | any;
};

type State = {
    loading?: boolean;
};

export const Account = (state: State = INIT_STATE, action: AccountActionType) => {
    switch (action.type) {
        case AccountActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case AccountActionTypes.GET_CUSTOMERS: {
                    return {
                        ...state,
                        customers: action.payload.data.customers,
                        customerLength: action.payload.data.length,
                        loading: false,
                    };
                }
                case AccountActionTypes.GET_CUSTOMER: {
                    return {
                        ...state,
                        customer: action.payload.data.customer,
                        loading: false,
                    };
                }
                case AccountActionTypes.UPDATE_CUSTOMER: {
                    return {
                        ...state,
                        customer: action.payload.data.customer,
                        loading: false,
                    };
                }
                default:
                    return {...state, loading: false};
            }
        case AccountActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                default:
                    return {...state, loading: false, error: action.payload.error};
            }
        case AccountActionTypes.GET_CUSTOMERS:
            return {
                ...state,
                loading: true
            }
        case AccountActionTypes.GET_CUSTOMER:
            return {
                ...state,
                loading: true
            }
        case AccountActionTypes.UPDATE_CUSTOMER:
            return {
                ...state,
                loading: true
            }
        case AccountActionTypes.LOGOUT:
            return {
                ...state,
                loading: false,
                customer: null,
                customerLength: null,
                customers: null,
            }
        default:
            return {...state};
    }
};
