import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {SagaIterator} from "@redux-saga/core";
import {AuthActionTypes} from "./constants";
import {authApiResponseError, authApiResponseSuccess} from "./actions";
import {authShopifyApi} from "../../api/auth";
import {APICore, setAuthorization} from "../../api/apiCore";

const api = new APICore();

function* authShopifyEvent(data: { payload: string, type: AuthActionTypes }): SagaIterator {
    try {
        const response = yield call(authShopifyApi, data.payload);
        setAuthorization(response.data);
        api.setLoggedInUser(response.data);
        yield put(authApiResponseSuccess(AuthActionTypes.AUTH_SHOPIFY, response.data));
    } catch (error: any) {
        yield put(authApiResponseError(AuthActionTypes.AUTH_SHOPIFY, error));
    }
}

export function* watchAuthShopifyEvent() {
    yield takeEvery(AuthActionTypes.AUTH_SHOPIFY, authShopifyEvent)
}

function* authSaga() {
    yield all([
        fork(watchAuthShopifyEvent),
    ]);
}

export default authSaga;
