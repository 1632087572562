export enum AccountActionTypes {
    API_RESPONSE_SUCCESS = '@@account/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@account/API_RESPONSE_ERROR',

    GET_CUSTOMERS = '@@account/GET_CUSTOMERS',
    GET_CUSTOMER = '@@account/GET_CUSTOMER',
    UPDATE_CUSTOMER = '@@account/UPDATE_CUSTOMER',

    LOGOUT = '@@account/LOGOUT',
}
