import {APICore} from "../../api/apiCore";
import {AuthActionTypes} from "./constants";

const api = new APICore();

const INIT_STATE = {
    user: api.getLoggedInUser(),
    loading: false,
};

type UserData = {
    id: number;
};

export type AuthActionType = {
    type:
        | AuthActionTypes.API_RESPONSE_SUCCESS
        | AuthActionTypes.API_RESPONSE_ERROR
        | AuthActionTypes.AUTH_SHOPIFY
    ;
    payload: {
        actionType?: string;
        data?: UserData | {} | any;
        error?: string;
    };
};

type State = {
    user?: UserData | {};
    loading?: boolean;
    value?: boolean;
};

export const Auth = (state: State = INIT_STATE, action: AuthActionType) => {
    switch (action.type) {
        case AuthActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case AuthActionTypes.AUTH_SHOPIFY: {
                    return {
                        ...state,
                        user: action.payload.data,
                        userLoggedIn: true,
                        loading: false,
                    };
                }
                default:
                    return { ...state, loading: false };
            }
        case AuthActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                default:
                    return { ...state, loading: false, error: action.payload.error };
            }
        default:
            return { ...state };
    }
};
