import {AccountActionTypes} from "./constants";
import {AccountActionType} from "./reducers";

export const accountApiResponseSuccess = (actionType: string, data: {}): AccountActionType => ({
    type: AccountActionTypes.API_RESPONSE_SUCCESS,
    payload: {actionType, data},
});

export const accountApiResponseError = (actionType: string, error: string): AccountActionType => ({
    type: AccountActionTypes.API_RESPONSE_ERROR,
    payload: {actionType, error},
});

export const getCustomers = (page: any): AccountActionType => ({
    type: AccountActionTypes.GET_CUSTOMERS,
    payload: page,
})

export const getCustomer = (id: any): AccountActionType => ({
    type: AccountActionTypes.GET_CUSTOMER,
    payload: id,
})

export const updateCustomer = (data: any): AccountActionType => ({
    type: AccountActionTypes.UPDATE_CUSTOMER,
    payload: data,
})

export const logout = (): AccountActionType => ({
    type: AccountActionTypes.LOGOUT,
    payload: null
})
