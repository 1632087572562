import { Navigate } from 'react-router-dom';
import {APICore} from "../api/apiCore";

const Root = () => {
    const user = new APICore().getLoggedInUser();

    if (!user) return <Navigate to={'/account/login'} />

    return <Navigate to={`/account/profile`} />;
};

export default Root;
