import React, {Suspense} from 'react';
import {useRoutes} from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import Root from './Root';
import {Spin} from "antd";
import VerticalLayout from "../pages/VerticalLayout";

// lazy load all the views

// auth
const Login = React.lazy(() => import('../pages/account/Login'));
const Logout = React.lazy(() => import('../pages/account/Logout'));
const Profile = React.lazy(() => import('../pages/account/Profile'));

export const loading = () => <Spin spinning={true}></Spin>;

type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({component: Component}: LoadComponentProps) => (
    <Suspense fallback={loading()}>
        <Component/>
    </Suspense>
);

const AllRoutes = () => {
    const Layout: React.ComponentType = VerticalLayout;

    return useRoutes([
        {path: '/', element: <Root/>},
        {
            path: '/',
            children: [
                {
                    path: 'account',
                    children: [
                        {path: 'login', element: <LoadComponent component={Login}/>},
                        {path: 'logout', element: <LoadComponent component={Logout}/>},
                    ],
                }
            ],
        },
        {
            path: '/',
            element: <PrivateRoute component={Layout} />,
            children: [
                {
                    path: 'account',
                    children: [
                        {path: 'profile', element: <LoadComponent component={Profile}/>},
                    ],
                }
            ],
        },
    ]);
};

export {AllRoutes};
