import {AuthActionTypes} from "./constants";
import {AuthActionType} from "./reducers";

export const authApiResponseSuccess = (actionType: string, data: {}): AuthActionType => ({
    type: AuthActionTypes.API_RESPONSE_SUCCESS,
    payload: {actionType, data},
});

export const authApiResponseError = (actionType: string, error: string): AuthActionType => ({
    type: AuthActionTypes.API_RESPONSE_ERROR,
    payload: {actionType, error},
});

export const authShopify = (data: any): AuthActionType => ({
    type: AuthActionTypes.AUTH_SHOPIFY,
    payload: data,
})
