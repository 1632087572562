import axios from 'axios';
import secureLocalStorage from "react-secure-storage";
import config from "../config";

// content type
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.baseURL = config.API_URL;

axios.interceptors.request.use(async (config: any) => {
    return config;
}, (error) => {
    return Promise.reject(error);
})

// intercepting to capture errors
axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        let message;

        if (error && error.response && error.response.status === 403) {
            window.location.href = '/access-denied';
        } else if (!error.response) {
            return Promise.reject(error.message);
        } else {
            switch (error.response.status) {
                case 400:
                    message = error.response.data;
                    break;
                case 401:
                    setAuthorization(null);
                    secureLocalStorage.removeItem(AUTH_SESSION_KEY);
                    window.location.href = '/account/login'
                    message = 'Invalid credentials';
                    break;
                case 403:
                    message = 'Access Forbidden';
                    break;
                case 404:
                    message = 'Sorry! the data you are looking for could not be found';
                    break;
                case 500:
                    message = error.response.data;
                    break;
                default: {
                    message =
                        error.response && error.response.data ? error.response.data['message'] : error.message || error;
                }
            }
            return Promise.reject(message);
        }
    }
);

const AUTH_SESSION_KEY = 'antera-bridge_user';

const setAuthorization = (user: any | null) => {
    if (user) {
        axios.defaults.headers.common['Authorization'] = `${user.store_id}:${user.token}`;
    } else {
        delete axios.defaults.headers.common['Authorization'];
    }
};

const getUserFromSession = (): any => {
    const user = secureLocalStorage.getItem(AUTH_SESSION_KEY);
    return user ? user : null;
};

class APICore {
    get = (url: string, params: any) => {
        let response;
        if (params) {
            const queryString = params
                ? Object.keys(params)
                    .map((key) => key + '=' + params[key])
                    .join('&')
                : '';
            response = axios.get(`${url}?${queryString}`, params);
        } else {
            response = axios.get(`${url}`, params);
        }
        return response;
    };

    create = (url: string, data: any) => {
        return axios.post(url, data);
    };

    update = (url: string, data: any) => {
        return axios.put(url, data);
    };

    delete = (url: string) => {
        return axios.delete(url);
    };

    isUserAuthenticated = () => {
        const user = this.getLoggedInUser();

        return !(!user || (user && !user.token));
    };

    setLoggedInUser = (session: any) => {
        if (session) {
            secureLocalStorage.setItem(AUTH_SESSION_KEY, session);
            setAuthorization(session);
        } else {
            secureLocalStorage.clear();
        }
    };

    getLoggedInUser = () => {
        return getUserFromSession();
    };

    logOut = () => {
        secureLocalStorage.clear();
    }
}

/*
Check if token available in session
*/
let userSession = getUserFromSession();
if (userSession) {
    const {user} = userSession;
    if (user) {
        setAuthorization(user);
    }
}

export {APICore, setAuthorization};
