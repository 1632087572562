import {APICore} from "./apiCore";

const api = new APICore();

export function getCustomersApi(page: any) {
    const baseUrl = `/account/customers?page=${page}`;
    return api.get(`${baseUrl}`, null);
}

export function getCustomerApi(id: any) {
    const baseUrl = `/account/customers/${id}`;
    return api.get(`${baseUrl}`, null);
}

export function updateCustomerApi(data: any) {
    const baseUrl = `/account/customers/`;
    return api.create(`${baseUrl}`, data);
}
