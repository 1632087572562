import React, {useEffect, useState} from 'react';
import {Outlet} from 'react-router-dom';
import {Layout, Menu, theme, Typography} from "antd";
import {LogoutOutlined, UserOutlined} from "@ant-design/icons";

const {Content, Sider} = Layout;

const VerticalLayout = () => {
    const [selectedLocation, setSelectedLocation] = useState('/account/profile');

    const {
        token: {colorBgContainer, colorPrimary},
    } = theme.useToken();

    useEffect(() => {
        if (window.location.pathname !== selectedLocation)
            window.location.href = selectedLocation;
    }, [selectedLocation]);

    return (
        <Layout style={{height: '100vh'}}>
            <Sider breakpoint="sm"
                   collapsedWidth="0">
                <Typography.Title level={5}
                                  style={{
                                      textAlign: 'center',
                                      background: colorBgContainer,
                                      padding: 8,
                                      borderRadius: 4,
                                      margin: 4
                                  }}>Antera Brdige</Typography.Title>
                <Menu
                    mode={'inline'}
                    onSelect={({key}) => setSelectedLocation(key)}
                    defaultSelectedKeys={[selectedLocation]}
                    style={{borderRight: 0, background: colorPrimary}}
                    items={[
                        {label: 'Customers', key: '/account/profile', icon: <UserOutlined/>},
                        {label: 'Logout', key: '/account/logout', icon: <LogoutOutlined/>},
                    ]}
                />
            </Sider>
            <Layout>
                <Content
                    style={{
                        padding: '24px 48px',
                        margin: 0,
                    }}
                >
                    <Outlet/>
                </Content>
            </Layout>
        </Layout>
    );
};
export default VerticalLayout;
