import {Navigate, useLocation} from 'react-router-dom';
import useUser from "../hooks/useUser";

type PrivateRouteProps = {
    component: React.ComponentType;
    roles?: string[];
};

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const PrivateRoute = ({component: RouteComponent, roles, ...rest}: PrivateRouteProps) => {
    let location = useLocation();
    const [loggedInUser] = useUser();

    /**
     * not logged in so redirect to login page with the return url
     */
    if (!loggedInUser?.user.token) {
        return <Navigate to={'/account/login'} state={{from: location}} replace/>;
    }

    // check if route is restricted by role
    if (roles && roles.indexOf(loggedInUser.role) === -1) {
        return <Navigate to={{pathname: '/'}}/>;
    }

    return <RouteComponent/>;
};

export default PrivateRoute;
