import Routes from "./routes/Routes";

import React from 'react';
import './App.css';
import {ConfigProvider} from "antd";

function App() {
    return (
        <ConfigProvider
            theme={{
                components: {
                    Button: {
                        colorPrimary: '#2b965c',
                        algorithm: true, // Enable algorithm,
                        colorWhite: '#eeeeee'
                    },
                    Layout: {
                        triggerBg: '#2b965c',
                        siderBg: '#2b965c'
                    }
                },
                token: {
                    colorPrimary: '#2b965c',
                    colorPrimaryHover: '#2b965cCC',
                    colorPrimaryTextHover: '#252525',
                    colorPrimaryTextActive: '#252525',
                    colorPrimaryText: '#252525',
                    borderRadius: 3,
                    colorPrimaryActive: '#252525',
                    colorPrimaryBgHover: '#252525',
                    colorBgContainer: '#fefefe',
                    colorLink: '#2b965c',
                }
            }}
        ><Routes/></ConfigProvider>
    );
}

export default App;
