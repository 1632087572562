const config = {
    API_URL: process.env.REACT_APP_API_URL,
    SECURE_LOCAL_STORAGE_HASH_KEY: process.env.REACT_APP_SECURE_LOCAL_STORAGE_HASH_KEY,
    SECURE_LOCAL_STORAGE_PREFIX: process.env.REACT_APP_SECURE_LOCAL_STORAGE_PREFIX,
    SHOPIFY_CLIENT_ID: process.env.REACT_APP_SHOPIFY_CLIENT_ID,
    SHOPIFY_CLIENT_SECRET: process.env.REACT_APP_SHOPIFY_CLIENT_SECRET,
    SHOPIFY_SCOPES: 'read_customers,read_products,write_products,read_orders,write_orders,write_fulfillments,write_assigned_fulfillment_orders,read_locations,read_assigned_fulfillment_orders,read_inventory,write_inventory',
    CLIENT_ID: process.env.REACT_APP_COGNITO_ID,
    CLIENT_DOMAIN: process.env.REACT_APP_COGNITO_DOMAIN
};

export default config;
