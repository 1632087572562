import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {SagaIterator} from "@redux-saga/core";
import {AccountActionTypes} from "./constants";
import {accountApiResponseError, accountApiResponseSuccess} from "./actions";
import {getCustomerApi, getCustomersApi, updateCustomerApi} from "../../api/account";
import {APICore} from "../../api/apiCore";

const api = new APICore();

function* getCustomersEvent(data: { payload: any, type: AccountActionTypes }): SagaIterator {
    try {
        const response = yield call(getCustomersApi, data.payload.page);
        yield put(accountApiResponseSuccess(AccountActionTypes.GET_CUSTOMERS, response.data));
    } catch (error: any) {
        yield put(accountApiResponseError(AccountActionTypes.GET_CUSTOMERS, error));
    }
}

function* getCustomerEvent(data: { payload: any, type: AccountActionTypes }): SagaIterator {
    try {
        const response = yield call(getCustomerApi, data.payload)
        yield put(accountApiResponseSuccess(AccountActionTypes.GET_CUSTOMER, response.data));
    } catch (error: any) {
        yield put(accountApiResponseError(AccountActionTypes.GET_CUSTOMER, error));
    }
}

function* updateCustomerEvent(data: { payload: any, type: AccountActionTypes }): SagaIterator {
    try {
        const response = yield call(updateCustomerApi, data.payload)
        yield put(accountApiResponseSuccess(AccountActionTypes.UPDATE_CUSTOMER, response.data));
    } catch (error: any) {
        yield put(accountApiResponseError(AccountActionTypes.UPDATE_CUSTOMER, error));
    }
}

function logoutCustomer(): any {
    api.logOut();
    window.location.href = '/account/login';
}

export function* watchGetCustomersEvent() {
    yield takeEvery(AccountActionTypes.GET_CUSTOMERS, getCustomersEvent)
}

export function* watchGetCustomerEvent() {
    yield takeEvery(AccountActionTypes.GET_CUSTOMER, getCustomerEvent)
}

export function* watchUpdateCustomerEvent() {
    yield takeEvery(AccountActionTypes.UPDATE_CUSTOMER, updateCustomerEvent)
}

export function* watchLogoutEvent() {
    yield takeEvery(AccountActionTypes.LOGOUT, logoutCustomer)
}

function* accountSaga() {
    yield all([
        fork(watchGetCustomersEvent),
        fork(watchGetCustomerEvent),
        fork(watchUpdateCustomerEvent),
        fork(watchLogoutEvent),
    ]);
}

export default accountSaga;
