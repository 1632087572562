import authSaga from "./auth/sagas";
import {all} from "redux-saga/effects";
import accountSaga from "./account/sagas";

export default function* rootSaga() {
    yield all([
        authSaga(),
        accountSaga()
    ]);
}
